import React from 'react'
import DefaultToggle from '../components/Toggle/DefaultToggle'

function ToggleComp() {
  return (
    <div className="d-flex flex-column gap-52">
      <DefaultToggle />
    </div>
  )
}

export default ToggleComp