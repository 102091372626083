import React from 'react'

function Radio({ variant = "default", size, disabled, label }) {
  // variant : default,Outline
  // size : large,small
  return (
    <div className='radio-container'>
      <input disabled={disabled} className={`radio radio-${variant} ${disabled ? 'radio-disabled' : ""} ${size ? "radio-" + size : ""}`} type="radio" name={`${variant}-${size}`} id={`${variant}-${size}`} />
      <label htmlFor={`${variant}-${size}`} className='radio-label'>{label}</label>
    </div>
  )
}

export default Radio