import React from 'react'
import PrimaryButton from '../components/Buttons/PrimaryButton';
import SecondaryButton from '../components/Buttons/SecondaryButton';
import OutlineButton from '../components/Buttons/OutlineButton';
import DangerButton from '../components/Buttons/DangerButton';
import GhostButton from '../components/Buttons/GhostButton';
import LinkButton from '../components/Buttons/LinkButton';

function ButtonComp() {
  return (
    <div className="d-flex flex-column gap-52">
      <PrimaryButton />
      <SecondaryButton />
      <OutlineButton />
      <DangerButton />
      <GhostButton />
      <LinkButton />
    </div>
  )
}

export default ButtonComp