import React from 'react'
import CompLayout from '../../theme/Layout/CompLayout'
import Toggle from '../../theme/Elements/Toggle/Toggle'

function DefaultToggle() {
  const commonDesc = "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page."
  return (
    <div>
      <h3 className="section-title">default</h3>
      <p className="comp-desc mt-12">Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page. Primary buttons should only appear once per container (not including the application header or in a modal dialog). Not every screen requires a primary button.</p>
      <div className="d-flex mt-32 flex-column gap-32">
        <CompLayout
          component={<Toggle size="large" />}
          title="large"
          desc={commonDesc}
        />
        <CompLayout
          component={<Toggle />}
          title="normal"
          desc={commonDesc}
        />
        <CompLayout
          component={<Toggle disable />}
          title="disable"
          desc={commonDesc}
        />
      </div>
    </div>
  )
}

export default DefaultToggle