import React from 'react'
function CheckBox({ variant = "default", size, checked, disabled, labelClass, wrapperClass, label }) {
  return (
    <div className={`checkbox-wrapper ${wrapperClass ? wrapperClass : ""}`}>
      <input disabled={disabled} checked={checked} className={`checkbox checkbox-${variant} ${size ? "checkbox" + size : ""}`} type="checkbox" name={`${variant}-${size === undefined ? "normal" : size}`} id={`${variant}-${size === undefined ? "normal" : size}`} />
      {
        label &&
        <label for={`${variant}-${size === undefined ? "normal" : size}`} className={`checkbox-label checkbox-label-${size === undefined ? "normal" : size} ${disabled ? "checkbox-label-disabled" : ""} ${labelClass ? labelClass : ""}`}>{label}</label>
      }
    </div>
  )
}

export default CheckBox