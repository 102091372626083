import React, { useState } from 'react';
import './toggle.scss';

function Toggle({ disable, initialState = false, size }) {
  const [isToggled, setIsToggled] = useState(initialState);

  const toggleSwitch = () => {
    if (disable) {
      setIsToggled(initialState)
    }
    else {
      setIsToggled(!isToggled);
    }
  };

  return (
    <div className={`toggle ${size ? `toggle-${size}` : ""} ${isToggled ? "toggle-active" : ""} ${disable ? "toggle-disable" : ""}`} onClick={toggleSwitch}>
      <input type="checkbox" className="toggle-checkbox" checked={isToggled} readOnly />
      <label className="toggle-label" htmlFor="toggle-checkbox">
        <span className="toggle-slider"></span>
      </label>
    </div>
  );
}

export default Toggle;
